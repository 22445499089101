import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import "./i18n";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback="loading">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
);
